export function setLocalStorage(key: string, value) {
  if (typeof window !== 'undefined') {
    window.localStorage.setItem(key, typeof value === 'object' ? JSON.stringify(value) : value);
  }
}

export function getLocalStorage(key: string) {
  if (typeof window !== 'undefined') {
    const value = window.localStorage.getItem(key);
    try {
      return value ? JSON.parse(value) : value;
    } catch (e) {
      //
    }
    return value;
  }
  return null;
}

export function removeLocalStorage(key: string): void {
  if (typeof window !== 'undefined') {
    window.localStorage.removeItem(key);
  }
}

export function clearAllLocalStorage(): void {
  if (typeof window !== 'undefined') {
    window.localStorage.clear();
  }
}
